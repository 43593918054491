<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <router-link
        :to="{ name: 'Disk', params: { diskid: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        <div class="hover">
          {{ name }}
        </div>
        <!--        <text-highlight :queries="filtredSearch">-->
        <!--          {{ item.name ? item.name : item.id }}</text-highlight-->
        <!--        >-->
      </router-link>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template #attr>
      <div class="tariffs-table-item__attrs">
        <!--          class="p-button p-button-text p-button-secondary tariffs-table-item__attrs-btn"-->
        <!--          v-tooltip.right="'Тип диска'"-->
        <Button disabled="disabled" class="tariffs-table-item__attrs-btn">
          <svg-icon size="large" :name="typeDisk(item)" class="specs-item__icon" />
        </Button>
        <Button
          v-if="isBootable"
          disabled="disabled"
          v-tooltip.right="'Загрузочный'"
          icon="pi pi-server"
          class="tariffs-table-item__attrs-btn"
        />
        <Button
          v-if="isRootDisk"
          disabled="disabled"
          v-tooltip.right="'Основной (root) диск'"
          icon="pi pi-lock"
          class="tariffs-table-item__attrs-btn"
        />
      </div>
    </template>
    <template v-if="instance" #inst>
      <div>
        <router-link
          :to="{ name: 'Server', params: { serverid: instance.id } }"
          class="tariffs-table-item__link medium-text"
        >
          <div class="hover">
            {{ instance.name }}
          </div>
        </router-link>
      </div>
    </template>
    <template #size>
      <div class="tariffs-table-item__ip">{{ item.size }} ГБ</div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #created>
      <div class="tariffs-table-item__ip">
        {{ created(item).split(' ')[0] }} <br />
        {{ created(item).split(' ')[1] }}
      </div>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <disk-context-menu
          slot="popover"
          :tariff="item"
          :type-action="type"
          :root="isRootDisk"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import DiskContextMenu from './DiskContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format, parseISO } from 'date-fns';
import Button from 'primevue/button';
import { ru } from 'date-fns/locale';
import TextHighlight from 'vue-text-highlight';
import Tooltip from 'primevue/tooltip';
export default {
  name: 'TariffsTableDiskRow',
  components: {
    BaseTableRow,
    MainLabel,
    // TextHighlight,
    Button,
    DiskContextMenu,
  },
  // directives: {
  //   tooltip: Tooltip,
  // },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    filtredSearch: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      required: true,
      validator: value => ['disk', 'server'].includes(value),
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    serverId() {
      return this.item.attachments.length > 0 ? this.item.attachments[0].server_id : '';
      // return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    isBootable() {
      return this.item.bootable === 'true';
    },
    name() {
      return this.item.name ? this.item.name : this.item.id;
    },
    isRootDisk() {
      return (
        this.instance &&
        this.instance['os-extended-volumes:volumes_attached'].length &&
        this.instance['os-extended-volumes:volumes_attached'][0]?.id === this.item.id
      );
    },
    id() {
      // console.log(this.servers);
      return this.item.id;
    },
    // currentDisk() {
    //   return this.disks.find(disk => disk.id === this.id);
    // },
    instance() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverId) || '';
    },
  },
  methods: {
    // isBootable(item) {
    //   return item.bootable === 'true' ? 'Да' : 'Нет';
    // },
    typeDisk(item) {
      return item.volume_type;
    },
    created(i) {
      return format(i.created_at, 'dd.MM.yyyy HH:mm:ss');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "SSD": "Сетевой диск SSD",
    "SSD-Lite": "Сетевой диск SSD-Lite",
    "HDD": "Сетевой диск HDD",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.p-button.p-button-icon-only {
  width: 1.557rem;
  //padding: .5rem 0;
}
.hover{
  text-overflow: ellipsis;
  width: 100%;
  //height: 100%
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    width: 100%
    //height: 100%
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;

      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}

.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__specs {
    flex: 1 1 auto;
    margin-top: 0.12rem;
  }
  &__attrs{
    display: flex;
    gap: 0.1rem;
    z-index 1
    &-btn {
      padding: 0 0
      width: 2rem
      background-color: transparent;
      color: #6c757d;
      border-color: transparent;


    }
  }
  &__link {
    width: 100%

    position: relative;
    //padding: 0.5em;
    //margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    height: 0.5rem
    //max-width: 230px;
    white-space: nowrap;
    background: var(--main-card-bg);
    //text-overflow: ellipsis;
    //overflow: hidden;
    z-index: 1;

    //+breakpoint(ml-and-up) {
    //  text-overflow: ellipsis;
    //  overflow: hidden;
    //  position: relative;
    //  z-index: 1;
    //}
    //&:hover {
    //  color: darken($primary-color, 20%);
    //}

    //&:hover::after {
    //  +breakpoint(ml-and-up) {
    //    content: '';
    //    display: block;
    //    size(2.5em, 100%);
    //    absolute(0px, 100%);
    //    background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    //  }
    //}
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
